.datatable-row {
    border-bottom: 1px solid #ddd; /* Adjust the color to your preference */
  }

  .custom-accept-button {
    background-color: red !important;
    border-color: red !important;
    padding: 0px!important;
    padding: 5px!important;
    border-radius: 10px;
    margin-left: 10px !important;
    font-size: 14px !important;
  }
  
  .custom-reject-button{
    border: 1px solid #4f46e5;
    background-color: white !important;
    color: #4f46e5 !important;
    padding: 0px!important;
    padding: 5px!important;
    border-radius: 10px;
    font-size: 14px !important;
  }
  