/* @import url('https://fonts.googleapis.com/css?family=Exo:400,700'); */

* {
    /* margin: 0; */
    /* padding: 0; */
    /* box-sizing: border-box; */
    /* Ensure consistent box sizing */
}

body {
    /* font-family: 'Exo', sans-serif; */
    height: auto !important;
     /* Make body full height */
    /* overflow: hidden; */
     /* Prevent scrolling */
}

.reg-row {
    display: flex;
    /* Enable Flexbox */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: auto !important;
    /* Ensure it takes the full height of the parent */
    width: 100%;
    margin: auto auto;
    /* Center the content */
}

.reg-card {
    background: transparent;
    /* Semi-transparent white background */
    border: none;
    /* Remove border if needed */
    border-radius: 10px;
    /* Optional: Add rounded corners */
    box-shadow: 0rem 0.8rem 1rem rgba(0, 0, 0, 0.5);
    /* Optional: Add shadow for better visibility */
    backdrop-filter: blur(25px);
    /* Apply blur effect */
    background-color: transparent;
    padding: 50px;
    z-index: 1000;
    width: 80%;
    height: 100%;
    padding-right: 25px;
    padding-left: 25px;
}

.reg-card-body {
    opacity: 0.1;
    /* Keep full opacity for text and elements inside */
    padding-top: 20px;
    /* Add some padding for content */
    padding-bottom: 20px;
    /* Add some padding for content */
    background: transparent;
    width: 40%;
    height: 100%!important;
}

.reg-context1 {
    width: 100%;
    height: 100vh;
    /* Full height to fill viewport */
    display: flex;
    /* Use flexbox for centering */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    position: relative;
    /* Allow absolute positioning of child elements */
    z-index: 1;
    /* Ensure it's above the circles */
}

.reg-context1 h1 {
    color: #000;
    font-size: 50px;
    text-align: center;
}

.reg-area {
    background: linear-gradient(#fff, #DEE5FD, #58cfe4);
    width: 100%;
    min-height: 100vh;
    height: auto;
    position: absolute;
    z-index: 0;

    /* Flexbox centering */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;      /* Center vertically */
}



.reg-circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.reg-circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: #58cfe4;
    /* background: rgba(255, 255, 255, 0.2); */
    animation: animate 25s linear infinite;
    bottom: -150px;
}

.rounded-input {
    width: 100%;
    /* Full width */
    padding: 10px;
    /* Padding inside the input */
    border: 1px solid #ccc;
    /* Light gray border */
    border-radius: 25px;
    /* Rounded corners */
    font-size: 16px;
    /* Text size */
    transition: border-color 0.3s;
    /* Smooth transition for border color */
}

.rounded-input:focus {
    border-color: #4CAF50;
    /* Green border on focus */
    outline: none;
    /* Remove default outline */
}

.reg-circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}

.reg-circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.reg-circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.reg-circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.reg-circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.reg-circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.reg-circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.reg-circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.reg-circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.reg-circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

/* .p-stepper-action {
    background: transparent !important;
    font-size: small;
    margin-left: -15px;
} */

.p-stepper-header {
    width: "25%"!important;
    color: "#a0a0a0"!important;
    height: "40px";
    border-top: "0px";
    border-left: "0px";
    border-right: "0.51px solid #a0a0a0";
    background: "transparent";
    font-weight: "500"!important;
}

.p-stepper-panels {
    background: transparent !important;
}

.p-stepper-number {
    display: none;
}

.p-stepper-separator {
    display: none;
}

.p-stepper .p-stepper-nav:last-child {
    background-color: #4CAF50;
}

.p-highlight {
    background-color: #a0a0a0;
    overflow: hidden !important;
    box-sizing: border-box !important;
}

.stepper-button1 {
    width: "25%"!important;
    color: "#a0a0a0";
    height: "40px";
    border-top: "0px";
    border-left: "0px";
    border-right: "0.51px solid #a0a0a0";
    background: "transparent";
    font-weight: "500"
}


@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}