/* Prime Reat Table CSS End */
.p-button {
  border-radius: 5px;
  font-size: smaller;
}

.p-dialog-footer .p-component {
  margin: 2%;
}

.p-dropdown-items {
  padding-left: 0rem;
}

.p-button-rounded {
  border-radius: 50%;
}

.p-paginator-page {
  border-radius: 50%;
}

.p-splitbutton-defaultbutton {
  border-radius: 8% 0% 0% 8%
}

.p-splitbutton-menubutton {
  border-radius: 0% 10% 10% 0%;
  margin: 0px;
}

.login-card {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  /* background: url("images/login/login_bg.jpg"); */
  background-position: center;
  padding: 30px 12px;
}

.p-datatable-tbody .p-highlight {
  background-color: aliceblue !important;
}

.p-paginator-page {
  background-color: aliceblue !important;
}

.p-dialog-mask {
  z-index: 1000 !important;
}

.profile_row {
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #a0a0a0;
  padding-bottom: 1%;
}

.profile_field_heading {
  color: #1778b0;
  margin-top: 5px;
  margin-left: 1.3vw;
}

.profile_field_value {
  color: #A0A0A0;
  margin-top: 15px;
  margin-left: 1.3vw;
}